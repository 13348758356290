<template>
    <div
        v-if="element"
        :class="{ tooltip: true, 'pointer-events': isContextMenu }"
        @pointerdown="pointerDown"
        @pointerup="pointerUp"
        @pointermove="pointerMove"
        @wheel="wheel"
    >
        <div
            v-if="tooltipType === 'default' || tooltipType === 'absolute'"
            ref="defaultContainer"
            class="tooltip__default-container"
            :class="{
                'bottom-arrow': isTooltipBottom,
                'top-arrow': !isTooltipBottom,
            }"
            :style="defaultStyle"
            @pointerup="pointerUpContainer"
        >
            <div
                :class="{
                    'tooltip__default-content': true,
                    'no-padding': noPadding,
                }"
            >
                <slot />
            </div>
        </div>
        <div v-else class="tooltip__container" :style="styles" @pointerup="pointerUpContainer">
            <div :class="{ tooltip__content: true, 'no-padding': noPadding }">
                <slot />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        element: HTMLElement | null
        offsetX?: number
        offsetY?: number
        isContextMenu?: boolean
        noPadding?: boolean
        tooltipType?: string
        stickToBoundedElementBottom?: boolean
    }>(),
    {
        element: null,
        offsetX: 0,
        offsetY: 0,
        isContextMenu: false,
        noPadding: false,
        tooltipType: '',
        stickToBoundedElementBottom: false,
    }
)

const emit = defineEmits(['outsidePointerUp'])
const styles = ref<string>('')
const defaultStyle = ref<string>('')
const defaultContainer = ref<HTMLElement | null>(null)

const isTooltipBottom = computed(() => {
    if (!props.element || !defaultContainer.value) {
        return false
    }

    const rect = props.element.getBoundingClientRect()
    const tooltipRect = defaultContainer.value.getBoundingClientRect()

    const spaceAbove = rect.top
    return spaceAbove < tooltipRect.height
})

const updateStyles = () => {
    if (props.element) {
        const rect = props.element.getBoundingClientRect()
        styles.value = `top: ${rect.top + props.offsetY}px; left: ${rect.left + props.offsetX}px;`
    }
}

const updateDefaultStyle = async () => {
    await nextTick()
    if (props.element && defaultContainer.value) {
        const tooltipRect = defaultContainer.value.getBoundingClientRect()
        const rect = props.element.getBoundingClientRect()
        const viewportHeight = window.innerHeight
        const viewportWidth = window.innerWidth

        const spaceAbove = rect.top
        const spaceBelow = viewportHeight - rect.bottom

        let top = rect.top - tooltipRect.height - props.offsetY
        let left = rect.left + props.offsetX

        if (props.tooltipType === 'default') {
            if (spaceAbove < tooltipRect.height && spaceBelow > tooltipRect.height) {
                top = rect.bottom + props.offsetY
            } else if (spaceAbove < tooltipRect.height && spaceBelow < tooltipRect.height) {
                top =
                    Math.max(spaceAbove, spaceBelow) === spaceAbove
                        ? rect.top - tooltipRect.height - props.offsetY
                        : rect.bottom + props.offsetY
            }
            defaultStyle.value = `top: ${top}px; left: ${left}px;`
        }

        if (props.tooltipType === 'absolute') {
            const beforeElementOffset = 30
            const centerDivider = 2

            top = rect.top - (!props.stickToBoundedElementBottom ? tooltipRect.height : 0) - props.offsetY
            left = rect.left + rect.width / centerDivider - (beforeElementOffset + props.offsetX)

            if (top < 0 && spaceBelow > tooltipRect.height) {
                top = rect.bottom + props.offsetY
            } else if (top < 0 && spaceBelow < tooltipRect.height) {
                top =
                    Math.max(spaceAbove, spaceBelow) === spaceAbove
                        ? rect.top - tooltipRect.height - props.offsetY
                        : rect.bottom + props.offsetY
            }

            if (left < 0) {
                left = props.offsetX
            } else if (left + tooltipRect.width > viewportWidth) {
                left = viewportWidth - tooltipRect.width - props.offsetX
            }

            defaultStyle.value = `top: ${top}px; left: ${left}px;`
        }
    }
}

watch(
    () => props.element,
    () => {
        updateStyles()
        updateDefaultStyle()
    }
)

onMounted(() => {
    updateStyles()
    updateDefaultStyle()
})

const pointerDown = (event: PointerEvent) => {
    event.stopPropagation()
}

const pointerMove = (event: PointerEvent) => {
    event.stopPropagation()
}

const pointerUp = (event: PointerEvent) => {
    event.stopPropagation()
    emit('outsidePointerUp', event)
}

const pointerUpContainer = (event: PointerEvent) => {
    event.stopPropagation()
}

const wheel = (event: WheelEvent) => {
    event.preventDefault()
}
</script>
<style lang="scss" scoped>
.tooltip {
    position: fixed;
    inset: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: $setting-zi-tooltip;

    &.pointer-events {
        pointer-events: all;
    }

    &__container {
        box-shadow: 0 rem(2) rem(6) $setting-color-black;
        z-index: $setting-zi-tooltip;
        position: absolute;

        @include helper-color-bg(white);
        @include helper-border-radius(rem(10));
    }

    &__content {
        position: relative;
        padding: sp(xs);

        &.no-padding {
            padding: 0;
        }
    }

    &__default-container {
        z-index: $setting-zi-tooltip;
        max-width: rem(230);
        height: fit-content;
        position: absolute;
        text-align: left;

        @include helper-color-bg(white);
        @include helper-border($setting-color-gray-2, 1px, solid);
        @include helper-border-radius(rem(10));

        &.bottom-arrow::before {
            content: '';
            position: absolute;
            top: rem(-5.6);
            left: rem(30);
            width: rem(10);
            height: rem(10);
            transform: translateX(-50%) rotate(45deg);

            @include helper-color-bg(white);
            @include helper-border-b($setting-color-gray-2, 0, solid);
            @include helper-border-r($setting-color-gray-2, 0, solid);
            @include helper-border-t($setting-color-gray-2, 1px, solid);
            @include helper-border-l($setting-color-gray-2, 1px, solid);
        }

        &.top-arrow::before {
            content: '';
            position: absolute;
            bottom: rem(-5.6);
            left: rem(30);
            width: rem(10);
            height: rem(10);
            transform: translateX(-50%) rotate(-135deg);

            @include helper-color-bg(white);
            @include helper-border-b($setting-color-gray-2, 0, solid);
            @include helper-border-r($setting-color-gray-2, 0, solid);
            @include helper-border-t($setting-color-gray-2, 1px, solid);
            @include helper-border-l($setting-color-gray-2, 1px, solid);
        }
    }

    &__default-content {
        position: relative;
        padding: sp(s);
        white-space: normal;
        height: min-content;
        overflow: hidden;

        @include helper-font-weight(regular);
        @include helper-font-line-height(5);
        @include helper-font-size(smaller);
    }
}
</style>
